import { post, get, downFile } from 'common/request'

export default {
  //根据ID查询
  getById: id => get(`/scBulidingManagement/getByBuilId?id=${id}`),
  //新增
  add: args => post('/scBulidingManagement/add', args),
  //分页查询
  list: params => get('/residentArchives/list', params),
  //分页查询
  // list: params => get('/residentArchives/list', params),
  //根据ID删除
  deleteById: id => post(`/scBulidingManagement/delete?id=${id}`),
  // 根据id获取任楼详情
  getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // 网格树
  gridTree: args => get('/scGridManagement/getTreeList', args),
  //查询所有小区
  queryAll: args => get('/scGridManagement/queryAllPage', args),
  //分页查询单元楼层
  queryList: args => get('/scUnitInfo/queryList', args),
  //编辑单元楼栋
  edit: args => post('/scUnitInfo/edit', args),
  //获取网格树
  getTreeList: args => get('/scGridManagement/getTreeList', args),
  //根据ID查询单元楼层
  getByIdunit: id => get(`/scUnitInfo/getById?id=${id}`),
  //楼栋可视化
  getVisualData: id => get(`/scBulidingManagement/getVisualData?id=${id}`),
  //列表查询
  listhouse: args => get('/residentArchives/list', args),
  //查询所有
  listAll: args => get('/residentArchives/listAll', args),
  //根据ID修改
  update: args => post(`/residentArchives/update`, args),
  //批量修改

  updateList: args => post(`/residentArchives/updateList`, args),
  //导出
  export: args => downFile('/scBulidingManagement/export', args),
  //根据id查询
  getByIdlist: id => get(`/scUnitInfo/getById?id=${id}`),
  //批量删除
  deleteBatch: args => post(`/scBulidingManagement/deleteBatch`, args),
  //获取特殊人群数量
  GroupCounts: args => get('/visualization/getSpecialGroupCounts', args),
  //获取特殊人群占比
  GroupPercent: args => get('/visualization/getSpecialGroupPercent', args),
  //获取重点人群数量
  PopulationCounts: args => get('/visualization/getKeyPopulationCounts', args),
  //获取人群类别数量
  InfoCounts: args => get('/visualization/getResidentInfoCounts', args),
   /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
